import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="feats-general"></a><h2>Feats</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">General</span>
    </h2>
    <h5>ACROBATIC [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You get a +2 bonus on all Jump
checks and Tumble checks.</p>
    <h5>AGILE [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You get a +2 bonus on all
Balance checks and Escape Artist checks.</p>
    <h5>ALERTNESS [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You get a +2 bonus on all
Listen checks and Spot checks.</p>
    <p><b>Special</b>: The master of a familiar gains the benefit of
the Alertness feat whenever the familiar is within arm&#8217;s reach.</p>
    <h5>ANIMAL AFFINITY [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You get a +2 bonus on all
Handle Animal checks and Ride checks.</p>
    <h5>ARMOR PROFICIENCY (HEAVY) [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Armor Proficiency
(light), Armor Proficiency (medium).</p>
    <p><b>Benefit</b>: See Armor Proficiency (light).</p>
    <p><b>Normal</b>: See Armor Proficiency (light).</p>
    <p><b>Special</b>: Fighters, paladins, and clerics automatically
have Armor Proficiency (heavy) as a bonus feat. They need not select it.</p>
    <h5>ARMOR PROFICIENCY (LIGHT) [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: When you wear a type of armor
with which you are proficient, the armor check penalty for that armor
applies only to Balance, Climb, Escape Artist, Hide, Jump, Move
Silently, Pick Pocket, and Tumble checks.</p>
    <p><b>Normal</b>: A character who is wearing armor with which she
is not proficient applies its armor check penalty to attack rolls and
to all skill checks that involve moving, including Ride.</p>
    <p><b>Special</b>: All characters except wizards, sorcerers, and
monks automatically have Armor Proficiency (light) as a bonus feat.
They need not select it.</p>
    <h5>ARMOR PROFICIENCY (MEDIUM) [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Armor Proficiency (light).</p>
    <p><b>Benefit</b>: See Armor Proficiency (light).</p>
    <p><b>Normal</b>: See Armor Proficiency (light).</p>
    <p><b>Special</b>: Fighters, barbarians, paladins, clerics,
druids, and bards automatically have Armor Proficiency (medium) as a
bonus feat. They need not select it.</p>
    <h5>ATHLETIC [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You get a +2 bonus on all
Climb checks and Swim checks.</p>
    <h5>AUGMENT SUMMONING [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Spell Focus (conjuration).</p>
    <p><b>Benefit</b>: Each creature you conjure with any summon
spell gains a +4 enhancement bonus to Strength and Constitution for the
duration of the spell that summoned it.</p>
    <h5>BLIND-FIGHT [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: In melee, every time you miss
because of concealment, you can reroll your miss chance percentile roll
one time to see if you actually hit.</p>
    <p>An invisible attacker gets no advantages related to hitting
you in melee. That is, you don&#8217;t lose your Dexterity bonus to Armor
Class, and the attacker doesn&#8217;t get the usual +2 bonus for being
invisible. The invisible attacker&#8217;s bonuses do still apply for ranged
attacks, however.</p>
    <p>You take only half the usual penalty to speed for being unable
to see. Darkness and poor visibility in general reduces your speed to
three-quarters normal, instead of one-half.</p>
    <p><b>Normal</b>: Regular attack roll modifiers for invisible
attackers trying to hit you apply, and you lose your Dexterity bonus to
AC. The speed reduction for darkness and poor visibility also applies.</p>
    <p><b>Special</b>: The Blind-Fight feat is of no use against a
character who is the subject of a blink spell.</p>
    <p>A fighter may select Blind-Fight as one of his fighter bonus
feats.</p>
    <h5>CLEAVE [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Str 13, Power Attack.</p>
    <p><b>Benefit</b>: If you deal a creature enough damage to make
it drop (typically by dropping it to below 0 hit points or killing it),
you get an immediate, extra melee attack against another creature
within reach. You cannot take a 5-foot step before making this extra
attack. The extra attack is with the same weapon and at the same bonus
as the attack that dropped the previous creature. You can use this
ability once per round.</p>
    <p><b>Special</b>: A fighter may select Cleave as one of his
fighter bonus feats.</p>
    <h5>COMBAT CASTING [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You get a +4 bonus on
Concentration checks made to cast a spell or use a spell-like ability
while on the defensive or while you are grappling or pinned.</p>
    <h5>COMBAT EXPERTISE [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Int 13.</p>
    <p><b>Benefit</b>: When you use the attack action or the full
attack action in melee, you can take a penalty of as much as &#8211;5 on your
attack roll and add the same number (+5 or less) as a dodge bonus to
your Armor Class. This number may not exceed your base attack bonus.
The changes to attack rolls and Armor Class last until your next action.</p>
    <p><b>Normal</b>: A character without the Combat Expertise feat
can fight defensively while using the attack or full attack action to
take a &#8211;4 penalty on attack rolls and gain a +2 dodge bonus to Armor
Class.</p>
    <p><b>Special</b>: A fighter may select Combat Expertise as one
of his fighter bonus feats.</p>
    <h5>COMBAT REFLEXES [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You may make a number of
additional attacks of opportunity equal to your Dexterity bonus.</p>
    <p>With this feat, you may also make attacks of opportunity while
flat-footed.</p>
    <p><b>Normal</b>: A character without this feat can make only one
attack of opportunity per round and can&#8217;t make attacks of opportunity
while flat-footed.</p>
    <p><b>Special</b>: The Combat Reflexes feat does not allow a
rogue to use her opportunist ability more than once per round.</p>
    <p>A fighter may select Combat Reflexes as one of his fighter
bonus feats.</p>
    <p>A monk may select Combat Reflexes as a bonus feat at 2nd level.</p>
    <h5>DECEITFUL [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You get a +2 bonus on all
Disguise checks and Forgery checks.</p>
    <h5>DEFLECT ARROWS [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 13, Improved Unarmed
Strike.</p>
    <p><b>Benefit</b>: You must have at least one hand free (holding
nothing) to use this feat. Once per round when you would normally be
hit with a ranged weapon, you may deflect it so that you take no damage
from it. You must be aware of the attack and not flatfooted. Attempting
to deflect a ranged weapon doesn&#8217;t count as an action. Unusually
massive ranged weapons and ranged attacks generated by spell effects
can&#8217;t be deflected.</p>
    <p><b>Special:</b>A monk may select Deflect Arrows as a bonus
feat at 2nd level, even if she does not meet the prerequisites.</p>
    <p>A fighter may select Deflect Arrows as one of his fighter
bonus feats.</p>
    <h5>DEFT HANDS [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You get a +2 bonus on all
Sleight of Hand checks and Use Rope checks.</p>
    <h5>DIEHARD [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Endurance.</p>
    <p><b>Benefit</b>: When reduced to between &#8211;1 and &#8211;9 hit points,
you automatically become stable. You don&#8217;t have to roll d% to see if
you lose 1 hit point each round.</p>
    <p>When reduced to negative hit points, you may choose to act as
if you were disabled, rather than dying. You must make this decision as
soon as you are reduced to negative hit points (even if it isn&#8217;t your
turn). If you do not choose to act as if you were disabled, you
immediately fall unconscious.</p>
    <p>When using this feat, you can take either a single move or
standard action each turn, but not both, and you cannot take a full
round action. You can take a move action without further injuring
yourself, but if you perform any standard action (or any other action
deemed as strenuous, including some free actions, such as casting a
quickened spell) you take 1 point of damage after completing the act.
If you reach &#8211;10 hit points, you immediately die.</p>
    <p><b>Normal</b>: A character without this feat who is reduced to
between &#8211;1 and &#8211;9 hit points is unconscious and dying.</p>
    <h5>DILIGENT [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You get a +2 bonus on all
Appraise checks and Decipher Script checks.</p>
    <h5>DODGE [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Dex 13.</p>
    <p><b>Benefit</b>: During your action, you designate an opponent
and receive a +1 dodge bonus to Armor Class against attacks from that
opponent. You can select a new opponent on any action.</p>
    <p>A condition that makes you lose your Dexterity bonus to Armor
Class (if any) also makes you lose dodge bonuses. Also, dodge bonuses
stack with each other, unlike most other types of bonuses.</p>
    <p><b>Special</b>: A fighter may select Dodge as one of his
fighter bonus feats.</p>
    <h5>ENDURANCE [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You gain a +4 bonus on the
following checks and saves: Swim checks made to resist nonlethal
damage, Constitution checks made to continue running, Constitution
checks made to avoid nonlethal damage from a forced march, Constitution
checks made to hold your breath, Constitution checks made to avoid
nonlethal damage from starvation or thirst, Fortitude saves made to
avoid nonlethal damage from hot or cold environments, and Fortitude
saves made to resist damage from suffocation. Also, you may sleep in
light or medium armor without becoming fatigued.</p>
    <p><b>Normal</b>: A character without this feat who sleeps in
medium or heavier armor is automatically fatigued the next day.</p>
    <p><b>Special</b>: A ranger automatically gains Endurance as a
bonus feat at 3rd level. He need not select it.</p>
    <h5>ESCHEW MATERIALS [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You can cast any spell that
has a material component costing 1 gp or less without needing that
component. (The casting of the spell still provokes attacks of
opportunity as normal.) If the spell requires a material component that
costs more than 1 gp, you must have the material component at hand to
cast the spell, just as normal.</p>
    <h5>EXOTIC WEAPON PROFICIENCY [GENERAL]</h5>
    <p className="initial">Choose a type of exotic weapon. You understand
how to use that type of exotic weapon in combat.</p>
    <p><b>Prerequisite</b>: Base attack bonus +1 (plus Str 13 for
bastard sword or dwarven waraxe).</p>
    <p><b>Benefit</b>: You make attack rolls with the weapon normally.</p>
    <p><b>Normal</b>: A character who uses a weapon with which he or
she is not proficient takes a &#8211;4 penalty on attack rolls.</p>
    <p><b>Special</b>: You can gain Exotic Weapon Proficiency
multiple times. Each time you take the feat, it applies to a new type
of exotic weapon. Proficiency with the bastard sword or the dwarven
waraxe has an additional prerequisite of Str 13.</p>
    <p>A fighter may select Exotic Weapon Proficiency as one of his
fighter bonus feats.</p>
    <h5>EXTRA TURNING [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Ability to turn or rebuke
creatures.</p>
    <p><b>Benefit</b>: Each time you take this feat, you can use your
ability to turn or rebuke creatures four more times per day than normal.</p>
    <p>If you have the ability to turn or rebuke more than one kind
of creature each of your turning or rebuking abilities gains four
additional uses per day.</p>
    <p><b>Normal</b>: Without this feat, a character can typically
turn or rebuke undead (or other creatures) a number of times per day
equal to 3 + his or her Charisma modifier.</p>
    <p><b>Special</b>: You can gain Extra Turning multiple times. Its
effects stack. Each time you take the feat, you can use each of your
turning or rebuking abilities four additional times per day.</p>
    <h5>FAR SHOT [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Point Blank Shot.</p>
    <p><b>Benefit</b>: When you use a projectile weapon, such as a
bow, its range increment increases by one-half (multiply by 1-1/2).
When you use a thrown weapon, its range increment is doubled.</p>
    <p><b>Special</b>: A fighter may select Far Shot as one of his
fighter bonus feats.</p>
    <h5>GREAT CLEAVE [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Str 13, Cleave, Power
Attack, base attack bonus +4.</p>
    <p><b>Benefit</b>: This feat works like Cleave, except that there
is no limit to the number of times you can use it per round.</p>
    <p><b>Special</b>: A fighter may select Great Cleave as one of
his fighter bonus feats.</p>
    <h5>GREAT FORTITUDE [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You get a +2 bonus on all
Fortitude saving throws.</p>
    <h5>GREATER SPELL FOCUS [GENERAL]</h5>
    <p className="initial">Choose a school of magic to which you already
have applied the Spell Focus feat.</p>
    <p><b>Benefit</b>: Add +1 to the Difficulty Class for all saving
throws against spells from the school of magic you select. This bonus
stacks with the bonus from Spell Focus.</p>
    <p><b>Special</b>: You can gain this feat multiple times. Its
effects do not stack. Each time you take the feat, it applies to a new
school of magic to which you already have applied the Spell Focus feat.</p>
    <h5>GREATER SPELL PENETRATION [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Spell Penetration.</p>
    <p><b>Benefit</b>: You get a +2 bonus on caster level checks
(1d20 + caster level) made to overcome a creature&#8217;s spell resistance.
This bonus stacks with the one from Spell Penetration.</p>
    <h5>GREATER TWO-WEAPON FIGHTING [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 19, Improved
Two-Weapon Fighting, Two-Weapon Fighting, base attack bonus +11.</p>
    <p><b>Benefit</b>: You get a third attack with your off-hand
weapon, albeit at a &#8211;10 penalty.</p>
    <p><b>Special</b>: A fighter may select Greater Two-Weapon
Fighting as one of his fighter bonus feats.</p>
    <p>An 11th-level ranger who has chosen the two-weapon combat
style is treated as having Greater Two-Weapon Fighting, even if he does
not have the prerequisites for it, but only when he is wearing light or
no armor.</p>
    <h5>GREATER WEAPON FOCUS [GENERAL]</h5>
    <p className="initial">Choose one type of weapon for which you have
already selected Weapon Focus. You can also choose unarmed strike or
grapple as your weapon for purposes of this feat.</p>
    <p><b>Prerequisites</b>: Proficiency with selected weapon, Weapon
Focus with selected weapon, fighter level 8th.</p>
    <p><b>Benefit</b>: You gain a +1 bonus on all attack rolls you
make using the selected weapon. This bonus stacks with other bonuses on
attack rolls, including the one from Weapon Focus (see below).</p>
    <p><b>Special</b>: You can gain Greater Weapon Focus multiple
times. Its effects do not stack. Each time you take the feat, it
applies to a new type of weapon.</p>
    <p>A fighter must have Greater Weapon Focus with a given weapon
to gain the Greater Weapon Specialization feat for that weapon.</p>
    <p>A fighter may select Greater Weapon Focus as one of his
fighter bonus feats.</p>
    <h5>GREATER WEAPON SPECIALIZATION [GENERAL]</h5>
    <p className="initial">Choose one type of weapon for which you have
already selected Weapon Specialization. You can also choose unarmed
strike or grapple as your weapon for purposes of this feat.</p>
    <p><b>Prerequisites</b>: Proficiency with selected weapon,
Greater Weapon Focus with selected weapon, Weapon Focus with selected
weapon, Weapon Specialization with selected weapon, fighter level 12th.</p>
    <p><b>Benefit</b>: You gain a +2 bonus on all damage rolls you
make using the selected weapon. This bonus stacks with other bonuses on
damage rolls, including the one from Weapon Specialization (see below).</p>
    <p><b>Special</b>: You can gain Greater Weapon Specialization
multiple times. Its effects do not stack. Each time you take the feat,
it applies to a new type of weapon.</p>
    <p>A fighter may select Greater Weapon Specialization as one of
his fighter bonus feats.</p>
    <h5>IMPROVED BULL RUSH [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Str 13, Power Attack.</p>
    <p><b>Benefit</b>: When you perform a bull rush you do not
provoke an attack of opportunity from the defender. You also gain a +4
bonus on the opposed Strength check you make to push back the defender.</p>
    <p><b>Special</b>: A fighter may select Improved Bull Rush as one
of his fighter bonus feats.</p>
    <h5>IMPROVED COUNTERSPELL [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: When counterspelling, you may
use a spell of the same school that is one or more spell levels higher
than the target spell.</p>
    <p><b>Normal</b>: Without this feat, you may counter a spell only
with the same spell or with a spell specifically designated as
countering the target spell.</p>
    <h5>IMPROVED CRITICAL [GENERAL]</h5>
    <p className="initial">Choose one type of weapon.</p>
    <p><b>Prerequisite</b>: Proficient with weapon, base attack bonus
+8.</p>
    <p><b>Benefit</b>: When using the weapon you selected, your
threat range is doubled.</p>
    <p><b>Special</b>: You can gain Improved Critical multiple times.
The effects do not stack. Each time you take the feat, it applies to a
new type of weapon.</p>
    <p>This effect doesn&#8217;t stack with any other effect that expands
the threat range of a weapon.</p>
    <p>A fighter may select Improved Critical as one of his fighter
bonus feats.</p>
    <h5>IMPROVED DISARM [GENERAL]</h5>
    <p className="initial">Prerequisites: Int 13, Combat Expertise.</p>
    <p><b>Benefit</b>: You do not provoke an attack of opportunity
when you attempt to disarm an opponent, nor does the opponent have a
chance to disarm you. You also gain a +4 bonus on the opposed attack
roll you make to disarm your opponent.</p>
    <p><b>Normal</b>: See the normal disarm rules.</p>
    <p><b>Special</b>: A fighter may select Improved Disarm as one of
his fighter bonus feats.</p>
    <p>A monk may select Improved Disarm as a bonus feat at 6th
level, even if she does not meet the prerequisites.
    </p>
    <h5><a id="improved-familiar"></a>IMPROVED FAMILIAR [GENERAL]</h5>
This feat allows spellcasters to acquire a new familiar from a
nonstandard list, but only when they could normally acquire a new
familiar.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisites:</span> Ability
to acquire a new familiar, compatible alignment, sufficiently high
level (see below).</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> When choosing
a
familiar, the creatures listed below are also available to the
spellcaster. The spellcaster may choose a familiar with an alignment up
to one step away on each of the alignment axes (lawful through chaotic,
good through evil).
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Familiar</th>
          <th>Alignment</th>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Arcane Spellcaster Level</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle"
          }}>Shocker
lizard </td>
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle"
          }}>Neutral
          </td>
          <td style={{
            "verticalAlign": "middle",
            "textAlign": "center"
          }}>5th
          </td>
        </tr>
        <tr>
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle"
          }}>Stirge</td>
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle"
          }}>Neutral</td>
          <td style={{
            "verticalAlign": "middle",
            "textAlign": "center"
          }}>5th</td>
        </tr>
        <tr>
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Formian
worker</td>
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Lawful
Neutral</td>
          <td style={{
            "verticalAlign": "middle",
            "backgroundColor": "rgb(242, 228, 188)",
            "textAlign": "center"
          }}>7th</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>Imp</td>
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>Lawful
Evil</td>
          <td style={{
            "verticalAlign": "middle",
            "backgroundColor": "rgb(255, 255, 255)",
            "textAlign": "center"
          }}>7th
          </td>
        </tr>
        <tr>
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Pseudodragon</td>
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Neutral
Good</td>
          <td style={{
            "verticalAlign": "middle",
            "backgroundColor": "rgb(242, 228, 188)",
            "textAlign": "center"
          }}>7th
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>Quasit</td>
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>Chaotic
Evil</td>
          <td style={{
            "verticalAlign": "middle",
            "backgroundColor": "rgb(255, 255, 255)",
            "textAlign": "center"
          }}>7th
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle"
          }}>
          </td>
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle"
          }}>
          </td>
          <td style={{
            "verticalAlign": "middle",
            "textAlign": "center"
          }}>
          </td>
        </tr>
      </tbody>
    </table>
    <p>Improved familiars otherwise use the rules for regular
familiars,
with two exceptions: If the creature&#8217;s type is something other than
animal, its type does not change; and improved familiars do not gain
the ability to speak with other creatures of their kind (although many
of them already have the ability to communicate).
      <p>{`The list in the table above presents only a few possible improved
familiars. Almost any creature of the same general size and power as
those on the list makes a suitable familiar. Nor is the master`}{`’`}{`s
alignment the only possible categorization. For instance, improved
familiars could be assigned by the master`}{`’`}{`s creature type or subtype,
as shown below.`}</p>
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th>Familiar</th>
          <th>Type/Subtype</th>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Arcane Spellcaster Level</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle"
          }}>Celestial
hawk<sup>1</sup> </td>
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle"
          }}>Good
          </td>
          <td style={{
            "verticalAlign": "middle",
            "textAlign": "center"
          }}>3rd
          </td>
        </tr>
        <tr>
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle"
          }}>Fiendish
Tiny viper snake<sup>2</sup></td>
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle"
          }}>Evil
          </td>
          <td style={{
            "verticalAlign": "middle",
            "textAlign": "center"
          }}>3rd
          </td>
        </tr>
        <tr>
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Air
elemental, Small</td>
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Air
          </td>
          <td style={{
            "verticalAlign": "middle",
            "backgroundColor": "rgb(242, 228, 188)",
            "textAlign": "center"
          }}>5th
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>Earth
elemental, Small</td>
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>Earth
          </td>
          <td style={{
            "verticalAlign": "middle",
            "backgroundColor": "rgb(255, 255, 255)",
            "textAlign": "center"
          }}>5th
          </td>
        </tr>
        <tr>
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Fire
elemental, Small</td>
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Fire
          </td>
          <td style={{
            "verticalAlign": "middle",
            "backgroundColor": "rgb(242, 228, 188)",
            "textAlign": "center"
          }}>5th
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>Shocker
lizard</td>
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>Electricity
          </td>
          <td style={{
            "verticalAlign": "middle",
            "backgroundColor": "rgb(255, 255, 255)",
            "textAlign": "center"
          }}>5th
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle"
          }}>Water
elemental, Small </td>
          <td style={{
            "textAlign": "left",
            "verticalAlign": "middle"
          }}>Water
          </td>
          <td style={{
            "verticalAlign": "middle",
            "textAlign": "center"
          }}>5th
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>Homunculus<sup>3</sup></td>
          <td style={{
            "verticalAlign": "top"
          }}>Undead
          </td>
          <td style={{
            "verticalAlign": "top",
            "textAlign": "center"
          }}>7th
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Ice
mephit</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Cold
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "textAlign": "center"
          }}>7th
          </td>
        </tr>
        <tr>
          <td colSpan="3" rowSpan="1" style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontStyle": "italic"
            }}>1</span> Or other celestial animal from
the standard familiar list.</td>
        </tr>
        <tr>
          <td colSpan="3" rowSpan="1" style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontStyle": "italic"
            }}>2</span> Or other fiendish animal from the
standard familiar list.&nbsp;&nbsp;&nbsp; </td>
        </tr>
        <tr>
          <td colSpan="3" rowSpan="1" style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontStyle": "italic"
            }}>3</span> The master must first create the
homunculus, substituting ichor or another 
            <p>{`part of the master`}{`’`}{`s body for blood if necessary.`}</p>
          </td>
        </tr>
      </tbody>
    </table>
    <h5>IMPROVED FEINT [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Int 13, Combat Expertise.</p>
    <p><b>Benefit</b>: You can make a Bluff check to feint in combat
as a move action.</p>
    <p><b>Normal</b>: Feinting in combat is a standard action.</p>
    <p>A fighter may select Improved Feint as one of his fighter
bonus feats.</p>
    <h5>IMPROVED GRAPPLE [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 13, Improved Unarmed
Strike.</p>
    <p><b>Benefit</b>: You do not provoke an attack of opportunity
when you make a touch attack to start a grapple. You also gain a +4
bonus on all grapple checks, regardless of whether you started the
grapple.</p>
    <p><b>Normal</b>: Without this feat, you provoke an attack of
opportunity when you make a touch attack to start a grapple.</p>
    <p><b>Special</b>: A fighter may select Improved Grapple as one
of his fighter bonus feats.</p>
    <p>A monk may select Improved Grapple as a bonus feat at 1st
level, even if she does not meet the prerequisites.</p>
    <h5>IMPROVED INITIATIVE [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You get a +4 bonus on
initiative checks.</p>
    <p><b>Special</b>: A fighter may select Improved Initiative as
one of his fighter bonus feats.</p>
    <h5>IMPROVED OVERRUN [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Str 13, Power Attack.</p>
    <p><b>Benefit</b>: When you attempt to overrun an opponent, the
target may not choose to avoid you. You also gain a +4 bonus on your
Strength check to knock down your opponent.</p>
    <p><b>Normal</b>: Without this feat, the target of an overrun can
choose to avoid you or to block you.</p>
    <p><b>Special</b>: A fighter may select Improved Overrun as one
of his fighter bonus feats.</p>
    <h5>IMPROVED PRECISE SHOT [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 19, Point Blank
Shot, Precise Shot, base attack bonus +11.</p>
    <p><b>Benefit</b>: Your ranged attacks ignore the AC bonus
granted to targets by anything less than total cover, and the miss
chance granted to targets by anything less than total concealment.
Total cover and total concealment provide their normal benefits against
your ranged attacks.</p>
    <p>In addition, when you shoot or throw ranged weapons at a
grappling opponent, you automatically strike at the opponent you have
chosen.</p>
    <p><b>Normal</b>: See the normal rules on the effects of cover
and concealment. Without this feat, a character who shoots or throws a
ranged weapon at a target involved in a grapple must roll randomly to
see which grappling combatant the attack strikes.</p>
    <p><b>Special</b>: A fighter may select Improved Precise Shot as
one of his fighter bonus feats.</p>
    <p>An 11th-level ranger who has chosen the archery combat style
is treated as having Improved Precise Shot, even if he does not have
the prerequisites for it, but only when he is wearing light or no armor.</p>
    <h5>IMPROVED SHIELD BASH [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Shield Proficiency.</p>
    <p><b>Benefit</b>: When you perform a shield bash, you may still
apply the shield&#8217;s shield bonus to your AC.</p>
    <p><b>Normal</b>: Without this feat, a character who performs a
shield bash loses the shield&#8217;s shield bonus to AC until his or her next
turn.</p>
    <p><b>Special</b>: A fighter may select Improved Shield Bash as
one of his fighter bonus feats.</p>
    <h5>IMPROVED SUNDER [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Str 13, Power Attack.</p>
    <p><b>Benefit</b>: When you strike at an object held or carried
by an opponent (such as a weapon or shield), you do not provoke an
attack of opportunity (see Sunder, page 158).</p>
    <p>You also gain a +4 bonus on any attack roll made to attack an
object held or carried by another character.</p>
    <p><b>Normal</b>: Without this feat, you provoke an attack of
opportunity when you strike at an object held or carried by another
character.</p>
    <p><b>Special</b>: A fighter may select Improved Sunder as one of
his fighter bonus feats (see page 38).</p>
    <h5>IMPROVED TRIP [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Int 13, Combat Expertise.</p>
    <p><b>Benefit</b>: You do not provoke an attack of opportunity
when you attempt to trip an opponent while you are unarmed. You also
gain a +4 bonus on your Strength check to trip your opponent.</p>
    <p>If you trip an opponent in melee combat, you immediately get a
melee attack against that opponent as if you hadn&#8217;t used your attack
for the trip attempt. </p>
    <p><b>Normal</b>: Without this feat, you provoke an attack of
opportunity when you attempt to trip an opponent while you are unarmed.</p>
    <p><b>Special</b>: At 6th level, a monk may select Improved Trip
as a bonus feat, even if she does not have the prerequisites.</p>
    <p>A fighter may select Improved Trip as one of his fighter bonus
feats.</p>
    <h5>IMPROVED TURNING [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Ability to turn or rebuke
creatures.</p>
    <p><b>Benefit</b>: You turn or rebuke creatures as if you were
one level higher than you are in the class that grants you the ability.</p>
    <h5>IMPROVED TWO-WEAPON FIGHTING [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 17, Two-Weapon
Fighting, base attack bonus +6.</p>
    <p><b>Benefit</b>: In addition to the standard single extra
attack you get with an off-hand weapon, you get a second attack with
it, albeit at a &#8211;5 penalty.</p>
    <p><b>Normal</b>: Without this feat, you can only get a single
extra attack with an off-hand weapon.</p>
    <p><b>Special</b>: A fighter may select Improved Two-Weapon
Fighting as one of his fighter bonus feats.</p>
    <p>A 6th-level ranger who has chosen the two-weapon combat style
is treated as having Improved Two-Weapon Fighting, even if he does not
have the prerequisites for it, but only when he is wearing light or no
armor.</p>
    <h5>IMPROVED UNARMED STRIKE [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You are considered to be armed
even when unarmed &#8212;that is, you do not provoke attacks of opportunity
from armed opponents when you attack them while unarmed. However, you
still get an attack of opportunity against any opponent who makes an
unarmed attack on you.</p>
    <p>In addition, your unarmed strikes can deal lethal or nonlethal
damage, at your option.</p>
    <p><b>Normal</b>: Without this feat, you are considered unarmed
when attacking with an unarmed strike, and you can deal only nonlethal
damage with such an attack.</p>
    <p><b>Special</b>: A monk automatically gains Improved Unarmed
Strike as a bonus feat at 1st level. She need not select it.</p>
    <p>A fighter may select Improved Unarmed Strike as one of his
fighter bonus feats.</p>
    <h5>INVESTIGATOR [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You get a +2 bonus on all
Gather Information checks and Search checks.</p>
    <h5>IRON WILL [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You get a +2 bonus on all Will
saving throws.</p>
    <h5>LEADERSHIP [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Character level 6th.</p>
    <p><b>Benefits</b>: Having this feat enables the character to
attract loyal companions and devoted followers, subordinates who assist
her. See the table below for what sort of cohort and how many followers
the character can recruit.</p>
    <p><b>Leadership Modifiers</b>: Several factors can affect a
character&#8217;s Leadership score, causing it to vary from the base score
(character level + Cha modifier). A character&#8217;s reputation (from the
point of view of the cohort or follower he is trying to attract) raises
or lowers his Leadership score:</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "175px"
          }}>Leader&#8217;s Reputation</th>
          <th>Modifier</th>
        </tr>
        <tr className="odd-row">
          <td>Great renown</td>
          <td align="center">+2</td>
        </tr>
        <tr>
          <td>Fairness and generosity</td>
          <td align="center">+1</td>
        </tr>
        <tr className="odd-row">
          <td>Special power</td>
          <td align="center">+1</td>
        </tr>
        <tr>
          <td>Failure</td>
          <td align="center">&#8211;1</td>
        </tr>
        <tr className="odd-row">
          <td>Aloofness</td>
          <td align="center">&#8211;1</td>
        </tr>
        <tr>
          <td className="last-row">Cruelty</td>
          <td className="last-row" align="center">&#8211;2</td>
        </tr>
      </tbody>
    </table>
    <p>Other modifiers may apply when the character tries to attract
a cohort:</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "375px"
          }}>The Leader . . .</th>
          <th>Modifier</th>
        </tr>
        <tr className="odd-row">
          <td>Has a familiar, special mount, or animal companion</td>
          <td align="center">&#8211;2</td>
        </tr>
        <tr>
          <td>Recruits a cohort of a different alignment</td>
          <td align="center">&#8211;1</td>
        </tr>
        <tr className="odd-row">
          <td>Caused the death of a cohort</td>
          <td align="center">&#8211;2<sup>*</sup></td>
        </tr>
        <tr>
          <td colSpan="2">* <i>Cumulative per cohort killed.</i></td>
        </tr>
      </tbody>
    </table>
    <p>Followers have different priorities from cohorts. When the
character tries to attract a new follower, use any of the following
modifiers that apply.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "375px"
          }}>The Leader . . .</th>
          <th>Modifier</th>
        </tr>
        <tr className="odd-row">
          <td>Has a stronghold, base of operations, guildhouse, or
the like</td>
          <td align="center">+2</td>
        </tr>
        <tr>
          <td>Moves around a lot</td>
          <td align="center">&#8211;1</td>
        </tr>
        <tr className="odd-row">
          <td>Caused the death of other followers</td>
          <td align="center">&#8211;1</td>
        </tr>
      </tbody>
    </table>
    <table style={{
      "marginTop": "0.5em"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th rowSpan="2" style={{
            "width": "100px"
          }}>Leadership
Score</th>
          <th rowSpan="2" style={{
            "width": "75px"
          }}>Cohort
Level</th>
          <th colSpan="6">Number of Followers by Level</th>
        </tr>
        <tr>
          <th>1st</th>
          <th>2nd</th>
          <th>3rd</th>
          <th>4th</th>
          <th>5th</th>
          <th>6th</th>
        </tr>
        <tr className="odd-row">
          <td>1 or lower</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>2</td>
          <td>1st</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>3</td>
          <td>2nd</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>4</td>
          <td>3rd</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>5</td>
          <td>3rd</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>6</td>
          <td>4th</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>7</td>
          <td>5th</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>8</td>
          <td>5th</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>9</td>
          <td>6th</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>10</td>
          <td>7th</td>
          <td>5</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>11</td>
          <td>7th</td>
          <td>6</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>12</td>
          <td>8th</td>
          <td>8</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>13</td>
          <td>9th</td>
          <td>10</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>14</td>
          <td>10th</td>
          <td>15</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>15</td>
          <td>10th</td>
          <td>20</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>16</td>
          <td>11th</td>
          <td>25</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>17</td>
          <td>12th</td>
          <td>30</td>
          <td>3</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>18</td>
          <td>12th</td>
          <td>35</td>
          <td>3</td>
          <td>1</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>19</td>
          <td>13th</td>
          <td>40</td>
          <td>4</td>
          <td>2</td>
          <td>1</td>
          <td>1</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>20</td>
          <td>14th</td>
          <td>50</td>
          <td>5</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>21</td>
          <td>15th</td>
          <td>60</td>
          <td>6</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>1</td>
        </tr>
        <tr>
          <td>22</td>
          <td>15th</td>
          <td>75</td>
          <td>7</td>
          <td>4</td>
          <td>2</td>
          <td>2</td>
          <td>1</td>
        </tr>
        <tr className="odd-row">
          <td>23</td>
          <td>16th</td>
          <td>90</td>
          <td>9</td>
          <td>5</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
        </tr>
        <tr>
          <td>24</td>
          <td>17th</td>
          <td>110</td>
          <td>11</td>
          <td>6</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
        </tr>
        <tr className="odd-row">
          <td>25 or higher</td>
          <td>17th</td>
          <td>135</td>
          <td>13</td>
          <td>7</td>
          <td>4</td>
          <td>2</td>
          <td>2</td>
        </tr>
      </tbody>
    </table>
    <p><i>Leadership Score</i>: A character&#8217;s base Leadership score
equals his level plus any Charisma modifier. In order to take into
account negative Charisma modifiers, this table allows for very low
Leadership scores, but the character must still be 6th level or higher
in order to gain the Leadership feat. Outside factors can affect a
character&#8217;s Leadership score, as detailed above.</p>
    <p><i>Cohort Level</i>: The character can attract a cohort of up
to this level. Regardless of a character&#8217;s Leadership score, he can
only recruit a cohort who is two or more levels lower than himself. The
cohort should be equipped with gear appropriate for its level. A
character can try to attract a cohort of a particular race, class, and
alignment. The cohort&#8217;s alignment may not be opposed to the leader&#8217;s
alignment on either the law-vs-chaos or good-vs-evil axis, and the
leader takes a Leadership penalty if he recruits a cohort of an
alignment different from his own.</p>
    <p>Cohorts earn XP as follows:</p>
    <dl>
      <dd>The cohort does not count as a party member when
determining the party&#8217;s XP.</dd>
      <dd>Divide the cohort&#8217;s level by the level of the PC with whom
he or she is associated (the character with the Leadership feat who
attracted the cohort).</dd>
      <dd>Multiply this result by the total XP awarded to the PC and
add that number of experience points to the cohort&#8217;s total.</dd>
      <dd>If a cohort gains enough XP to bring it to a level one
lower than the associated PC&#8217;s character level, the cohort does not
gain the new level&#8212;its new XP total is 1 less than the amount needed
attain the next level.</dd>
    </dl>
    <p><i>Number of Followers by Level</i>: The character can lead up
to the indicated number of characters of each level. Followers are
similar to cohorts, except they&#8217;re generally low-level NPCs. Because
they&#8217;re generally five or more levels behind the character they follow,
they&#8217;re rarely effective in combat.</p>
    <p>Followers don&#8217;t earn experience and thus don&#8217;t gain levels.
However, when a character with Leadership attains a new level, the
player consults the table above to determine if she has acquired more
followers, some of which may be higher level than the existing
followers. (You don&#8217;t consult the table to see if your cohort gains
levels, however, because cohorts earn experience on their own.)</p>
    <h5>LIGHTNING REFLEXES [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You get a +2 bonus on all
Reflex saving throws.</p>
    <h5>MAGICAL APTITUDE [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>:You get a +2 bonus on all
Spellcraft checks and Use Magic Device checks.</p>
    <h5>MANYSHOT [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 17, Point Blank
Shot, Rapid Shot, base attack bonus +6</p>
    <p><b>Benefit</b>: As a standard action, you may fire two arrows
at a single opponent within 30 feet. Both arrows use the same attack
roll (with a &#8211;4 penalty) to determine success and deal damage normally
(but see Special).</p>
    <p>For every five points of base attack bonus you have above +6,
you may add one additional arrow to this attack, to a maximum of four
arrows at a base attack bonus of +16. However, each arrow after the
second adds a cumulative &#8211;2 penalty on the attack roll (for a total
penalty of &#8211;6 for three arrows and &#8211;8 for four).</p>
    <p>Damage reduction and other resistances apply separately
against each arrow fired.</p>
    <p><b>Special</b>: Regardless of the number of arrows you fire,
you apply precision-based damage only once. If you score a critical
hit, only the first arrow fired deals critical damage; all others deal
regular damage.</p>
    <p>A fighter may select Manyshot as one of his fighter bonus
feats.</p>
    <p>A 6th-level ranger who has chosen the archery combat style is
treated as having Manyshot even if he does not have the prerequisites
for it, but only when he is wearing light or no armor.</p>
    <h5>MARTIAL WEAPON PROFICIENCY [GENERAL]</h5>
    <p className="initial">Choose a type of martial weapon. You
understand how to use that type of martial weapon in combat.</p>
    <p><b>Benefit</b>: You make attack rolls with the selected weapon
normally.</p>
    <p><b>Normal</b>: When using a weapon with which you are not
proficient, you take a &#8211;4 penalty on attack rolls.</p>
    <p><b>Special</b>: Barbarians, fighters, paladins, and rangers
are proficient with all martial weapons. They need not select this feat.</p>
    <p>You can gain Martial Weapon Proficiency multiple times. Each
time you take the feat, it applies to a new type of weapon.</p>
    <p>A cleric who chooses the War domain automatically gains the
Martial Weapon Proficiency feat related to his deity&#8217;s favored weapon
as a bonus feat, if the weapon is a martial one. He need not select it.</p>
    <h5>MOBILITY [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 13, Dodge.</p>
    <p><b>Benefit</b>: You get a +4 dodge bonus to Armor Class
against attacks of opportunity caused when you move out of or within a
threatened area. A condition that makes you lose your Dexterity bonus
to Armor Class (if any) also makes you lose dodge bonuses.</p>
    <p>Dodge bonuses stack with each other, unlike most types of
bonuses.</p>
    <p><b>Special</b>: A fighter may select Mobility as one of his
fighter bonus feats.</p>
    <h5>MOUNTED ARCHERY [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Ride 1 rank, Mounted
Combat.</p>
    <p><b>Benefit</b>: The penalty you take when using a ranged
weapon while mounted is halved: &#8211;2 instead of &#8211;4 if your mount is
taking a double move, and &#8211;4 instead of &#8211;8 if your mount is running.</p>
    <p><b>Special</b>: A fighter may select Mounted Archery as one of
his fighter bonus feats.</p>
    <h5>MOUNTED COMBAT [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Ride 1 rank.</p>
    <p><b>Benefit</b>: Once per round when your mount is hit in
combat, you may attempt a Ride check (as a reaction) to negate the hit.
The hit is negated if your Ride check result is greater than the
opponent&#8217;s attack roll. (Essentially, the Ride check result becomes the
mount&#8217;s Armor Class if it&#8217;s higher than the mount&#8217;s regular AC.)</p>
    <p><b>Special</b>: A fighter may select Mounted Combat as one of
his fighter bonus feats.</p>
    <h5>NATURAL SPELL [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Wis 13, wild shape
ability.</p>
    <p><b>Benefit</b>: You can complete the verbal and somatic
components of spells while in a wild shape. You substitute various
noises and gestures for the normal verbal and somatic components of a
spell.</p>
    <p>You can also use any material components or focuses you
possess, even if such items are melded within your current form. This
feat does not permit the use of magic items while you are in a form
that could not ordinarily use them, and you do not gain the ability to
speak while in a wild shape.</p>
    <h5>NEGOTIATOR [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You get a +2 bonus on all
Diplomacy checks and Sense Motive checks.</p>
    <h5>NIMBLE FINGERS [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You get a +2 bonus on all
Disable Device checks and Open Lock checks.</p>
    <h5>PERSUASIVE [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You get a +2 bonus on all
Bluff checks and Intimidate checks.</p>
    <h5>POINT BLANK SHOT [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You get a +1 bonus on attack
and damage rolls with ranged weapons at ranges of up to 30 feet.</p>
    <p><b>Special</b>: A fighter may select Point Blank Shot as one
of his fighter bonus feats.</p>
    <h5>POWER ATTACK [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Str 13.</p>
    <p><b>Benefit</b>: On your action, before making attack rolls for
a round, you may choose to subtract a number from all melee attack
rolls and add the same number to all melee damage rolls. This number
may not exceed your base attack bonus. The penalty on attacks and bonus
on damage apply until your next turn.</p>
    <p><b>Special</b>: If you attack with a two-handed weapon, or
with a one-handed weapon wielded in two hands, instead add twice the
number subtracted from your attack rolls. You can&#8217;t add the bonus from
Power Attack to the damage dealt with a light weapon (except with
unarmed strikes or natural weapon attacks), even though the penalty on
attack rolls still applies. (Normally, you treat a double weapon as a
one-handed weapon and a light weapon. If you choose to use a double
weapon like a two-handed weapon, attacking with only one end of it in a
round, you treat it as a two-handed weapon.)</p>
    <p>A fighter may select Power Attack as one of his fighter bonus
feats.</p>
    <h5>PRECISE SHOT [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Point Blank Shot.</p>
    <p><b>Benefit</b>: You can shoot or throw ranged weapons at an
opponent engaged in melee without taking the standard &#8211;4 penalty on
your attack roll.</p>
    <p><b>Special</b>: A fighter may select Precise Shot as one of
his fighter bonus feats.</p>
    <h5>QUICK DRAW [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Base attack bonus +1.</p>
    <p><b>Benefit</b>: You can draw a weapon as a free action instead
of as a move action. You can draw a hidden weapon (see the Sleight of
Hand skill) as a move action.</p>
    <p>A character who has selected this feat may throw weapons at
his full normal rate of attacks (much like a character with a bow).</p>
    <p><b>Normal</b>: Without this feat, you may draw a weapon as a
move action, or (if your base attack bonus is +1 or higher) as a free
action as part of movement. Without this feat, you can draw a hidden
weapon as a standard action.</p>
    <p><b>Special</b>: A fighter may select Quick Draw as one of his
fighter bonus feats.</p>
    <h5>RAPID RELOAD [GENERAL]</h5>
    <p className="initial">Choose a type of crossbow (hand, light, or
heavy).</p>
    <p><b>Prerequisite</b>: Weapon Proficiency (crossbow type chosen).</p>
    <p><b>Benefit</b>: The time required for you to reload your
chosen type of crossbow is reduced to a free action (for a hand or
light crossbow) or a move action (for a heavy crossbow). Reloading a
crossbow still provokes an attack of opportunity.</p>
    <p>If you have selected this feat for hand crossbow or light
crossbow, you may fire that weapon as many times in a full attack
action as you could attack if you were using a bow.</p>
    <p><b>Normal</b>: A character without this feat needs a move
action to reload a hand or light crossbow, or a full-round action to
reload a heavy crossbow.</p>
    <p><b>Special</b>: You can gain Rapid Reload multiple times. Each
time you take the feat, it applies to a new type of crossbow.</p>
    <p>A fighter may select Rapid Reload as one of his fighter bonus
feats.</p>
    <h5>RAPID SHOT [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 13, Point Blank Shot.</p>
    <p><b>Benefit</b>: You can get one extra attack per round with a
ranged weapon. The attack is at your highest base attack bonus, but
each attack you make in that round (the extra one and the normal ones)
takes a &#8211;2 penalty. You must use the full attack action to use this
feat.</p>
    <p><b>Special</b>: A fighter may select Rapid Shot as one of his
fighter bonus feats.</p>
    <p>A 2nd-level ranger who has chosen the archery combat style is
treated as having Rapid Shot, even if he does not have the
prerequisites for it, but only when he is wearing light or no armor.</p>
    <h5>RIDE-BY ATTACK [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Ride 1 rank, Mounted
Combat.</p>
    <p><b>Benefit</b>: When you are mounted and use the charge
action, you may move and attack as if with a standard charge and then
move again (continuing the straight line of the charge). Your total
movement for the round can&#8217;t exceed double your mounted speed. You and
your mount do not provoke an attack of opportunity from the opponent
that you attack.</p>
    <p><b>Special</b>: A fighter may select Ride-By Attack as one of
his fighter bonus feats.</p>
    <h5>RUN [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: When running, you move five
times your normal speed (if wearing light or no armor and carrying no
more than a light load) or four times your speed (if wearing medium or
heavy armor or carrying a medium or heavy load). If you make a jump
after a running start (see the Jump skill description), you gain a +4
bonus on your Jump check. While running, you retain your Dexterity
bonus to AC.</p>
    <p><b>Normal</b>: You move four times your speed while running
(if wearing light or no armor and carrying no more than a light load)
or three times your speed (if wearing medium or heavy armor or carrying
a medium or heavy load), and you lose your Dexterity bonus to AC.</p>
    <h5>SELF-SUFFICIENT [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You get a +2 bonus on all Heal
checks and Survival checks.</p>
    <h5>SHIELD PROFICIENCY [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You can use a shield and take
only the standard penalties.</p>
    <p><b>Normal</b>: When you are using a shield with which you are
not proficient, you take the shield&#8217;s armor check penalty on attack
rolls and on all skill checks that involve moving, including Ride
checks.</p>
    <p><b>Special</b>: Barbarians, bards, clerics, druids, fighters,
paladins, and rangers automatically have Shield Proficiency as a bonus
feat. They need not select it.</p>
    <h5>SHOT ON THE RUN [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 13, Dodge, Mobility,
Point Blank Shot, base attack bonus +4.</p>
    <p><b>Benefit</b>: When using the attack action with a ranged
weapon, you can move both before and after the attack, provided that
your total distance moved is not greater than your speed.</p>
    <p><b>Special</b>: A fighter may select Shot on the Run as one of
his fighter bonus feats.</p>
    <h5>SIMPLE WEAPON PROFICIENCY [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You make attack rolls with
simple weapons normally.</p>
    <p><b>Normal</b>: When using a weapon with which you are not
proficient, you take a &#8211;4 penalty on attack rolls.</p>
    <p><b>Special</b>: All characters except for druids, monks,
rogues, and wizards are automatically proficient with all simple
weapons. They need not select this feat.</p>
    <h5>SKILL FOCUS [GENERAL]</h5>
    <p className="initial">Choose a skill.</p>
    <p><b>Benefit</b>: You get a +3 bonus on all checks involving
that skill.</p>
    <p><b>Special</b>: You can gain this feat multiple times. Its
effects do not stack. Each time you take the feat, it applies to a new
skill.</p>
    <h5>SNATCH ARROWS [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 15, Deflect Arrows,
Improved Unarmed Strike.</p>
    <p><b>Benefit</b>: When using the Deflect Arrows feat you may
catch the weapon instead of just deflecting it. Thrown weapons can
immediately be thrown back at the original attacker (even though it
isn&#8217;t your turn) or kept for later use.</p>
    <p>You must have at least one hand free (holding nothing) to use
this feat.</p>
    <p><b>Special</b>: A fighter may select Snatch Arrows as one of
his fighter bonus feats.</p>
    <h5>SPELL FOCUS [GENERAL]</h5>
    <p className="initial">Choose a school of magic.</p>
    <p><b>Benefit</b>: Add +1 to the Difficulty Class for all saving
throws against spells from the school of magic you select.</p>
    <p><b>Special</b>: You can gain this feat multiple times. Its
effects do not stack. Each time you take the feat, it applies to a new
school of magic.</p>
    <h5>SPELL PENETRATION [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You get a +2 bonus on caster
level checks (1d20 + caster level) made to overcome a creature&#8217;s spell
resistance.</p>
    <h5>SPIRITED CHARGE [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Ride 1 rank, Mounted
Combat, Ride-By Attack.</p>
    <p><b>Benefit</b>: When mounted and using the charge action, you
deal double damage with a melee weapon (or triple damage with a lance).</p>
    <p><b>Special</b>: A fighter may select Spirited Charge as one of
his fighter bonus feats.</p>
    <h5>SPRING ATTACK [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 13, Dodge, Mobility,
base attack bonus +4.</p>
    <p><b>Benefit</b>: When using the attack action with a melee
weapon, you can move both before and after the attack, provided that
your total distance moved is not greater than your speed. Moving in
this way does not provoke an attack of opportunity from the defender
you attack, though it might provoke attacks of opportunity from other
creatures, if appropriate. You can&#8217;t use this feat if you are wearing
heavy armor.</p>
    <p>You must move at least 5 feet both before and after you make
your attack in order to utilize the benefits of Spring Attack.</p>
    <p><b>Special</b>: A fighter may select Spring Attack as one of
his fighter bonus feats.</p>
    <h5>STEALTHY [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You get a +2 bonus on all Hide
checks and Move Silently checks.</p>
    <h5>STUNNING FIST [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 13, Wis 13, Improved
Unarmed Strike, base attack bonus +8.</p>
    <p><b>Benefit</b>: You must declare that you are using this feat
before you make your attack roll (thus, a failed attack roll ruins the
attempt). Stunning Fist forces a foe damaged by your unarmed attack to
make a Fortitude saving throw (DC 10 + 1/2 your character level + your
Wis modifier), in addition to dealing damage normally. A defender who
fails this saving throw is stunned for 1 round (until just before your
next action). A stunned character can&#8217;t act, loses any Dexterity bonus
to AC, and takes a &#8211;2 penalty to AC. You may attempt a stunning attack
once per day for every four levels you have attained (but see Special),
and no more than once per round. Constructs, oozes, plants, undead,
incorporeal creatures, and creatures immune to critical hits cannot be
stunned.</p>
    <p><b>Special</b>: A monk may select Stunning Fist as a bonus
feat at 1st level, even if she does not meet the prerequisites. A monk
who selects this feat may attempt a stunning attack a number of times
per day equal to her monk level, plus one more time per day for every
four levels she has in classes other than monk.</p>
    <p>A fighter may select Stunning Fist as one of his fighter bonus
feats.</p>
    <h5>TOUGHNESS [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You gain +3 hit points.</p>
    <p><b>Special</b>: A character may gain this feat multiple times.
Its effects stack.</p>
    <h5>TOWER SHIELD PROFICIENCY [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Shield Proficiency.</p>
    <p><b>Benefit</b>: You can use a tower shield and suffer only the
standard penalties.</p>
    <p><b>Normal</b>: A character who is using a shield with which he
or she is not proficient takes the shield&#8217;s armor check penalty on
attack rolls and on all skill checks that involve moving, including
Ride.</p>
    <p><b>Special</b>: Fighters automatically have Tower Shield
Proficiency as a bonus feat. They need not select it.</p>
    <h5>TRACK [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: To find tracks or to follow
them for 1 mile requires a successful Survival check. You must make
another Survival check every time the tracks become difficult to follow.</p>
    <p>You move at half your normal speed (or at your normal speed
with a &#8211;5 penalty on the check, or at up to twice your normal speed
with a &#8211;20 penalty on the check). The DC depends on the surface and the
prevailing conditions, as given on the table below:</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "115px"
          }}>Surface</th>
          <th style={{
            "width": "90px"
          }}>Survival DC</th>
          <th style={{
            "width": "115px"
          }}>Surface</th>
          <th style={{
            "width": "90px"
          }}>Survival DC</th>
        </tr>
        <tr className="odd-row">
          <td>Very soft ground</td>
          <td align="center">5</td>
          <td>Firm ground</td>
          <td align="center">15</td>
        </tr>
        <tr>
          <td className="last-row">Soft ground</td>
          <td className="last-row" align="center">10</td>
          <td className="last-row">Hard ground</td>
          <td className="last-row" align="center">20</td>
        </tr>
      </tbody>
    </table>
    <p><i>Very Soft Ground</i>: Any surface (fresh snow, thick dust,
wet mud) that holds deep, clear impressions of footprints.</p>
    <p><i>Soft Ground</i>: Any surface soft enough to yield to
pressure, but firmer than wet mud or fresh snow, in which a creature
leaves frequent but shallow footprints.</p>
    <p><i>Firm Ground</i>: Most normal outdoor surfaces (such as
lawns, fields, woods, and the like) or exceptionally soft or dirty
indoor surfaces (thick rugs and very dirty or dusty floors). The
creature might leave some traces (broken branches or tufts of hair),
but it leaves only occasional or partial footprints.</p>
    <p><i>Hard Ground</i>: Any surface that doesn&#8217;t hold footprints
at all, such as bare rock or an indoor floor. Most streambeds fall into
this category, since any footprints left behind are obscured or washed
away. The creature leaves only traces (scuff marks or displaced
pebbles).</p>
    <p>Several modifiers may apply to the Survival check, as given on
the table below.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th>Condition</th>
          <th>Survival DC Modifier</th>
        </tr>
        <tr className="odd-row">
          <td>Every three creatures in the group being tracked</td>
          <td align="center">&#8211;1</td>
        </tr>
        <tr>
          <td>Size of creature or creatures being tracked:<sup>1</sup></td>
          <td>&nbsp;</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Fine</td>
          <td align="center">+8</td>
        </tr>
        <tr>
          <td className="indent">Diminutive</td>
          <td align="center">+4</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Tiny</td>
          <td align="center">+2</td>
        </tr>
        <tr>
          <td className="indent">Small</td>
          <td align="center">+1</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Medium</td>
          <td align="center">+0</td>
        </tr>
        <tr>
          <td className="indent">Large</td>
          <td align="center">-1</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Huge</td>
          <td align="center">-2</td>
        </tr>
        <tr>
          <td className="indent">Gargantuan</td>
          <td align="center">-4</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Colossal</td>
          <td align="center">-8</td>
        </tr>
        <tr>
          <td>Every 24 hours since the trail was made</td>
          <td align="center">+1</td>
        </tr>
        <tr className="odd-row">
          <td>Every hour of rain since the trail was made</td>
          <td align="center">+1</td>
        </tr>
        <tr>
          <td>Fresh snow cover since the trail was made</td>
          <td align="center">+10</td>
        </tr>
        <tr className="odd-row">
          <td>Poor visibility:<sup>2</sup></td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td className="indent">Overcast or moonless night</td>
          <td align="center">+6</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Moonlight</td>
          <td align="center">+3</td>
        </tr>
        <tr>
          <td className="indent">Fog or precipitation</td>
          <td align="center">+3</td>
        </tr>
        <tr className="odd-row">
          <td>Tracked party hides trail (and moves at half speed)</td>
          <td align="center">+5</td>
        </tr>
        <tr>
          <td colSpan="2">1 <i>For a group of mixed sizes, apply
only the modifier for the largest size category.</i></td>
        </tr>
        <tr>
          <td colSpan="2">2 <i>Apply only the largest modifier from
this category.</i></td>
        </tr>
      </tbody>
    </table>
    <p>If you fail a Survival check, you can retry after 1 hour
(outdoors) or 10 minutes (indoors) of searching.</p>
    <p><b>Normal</b>: Without this feat, you can use the Survival
skill to find tracks, but you can follow them only if the DC for the
task is 10 or lower. Alternatively, you can use the Search skill to
find a footprint or similar sign of a creature&#8217;s passage using the DCs
given above, but you can&#8217;t use Search to follow tracks, even if someone
else has already found them.</p>
    <p><b>Special</b>: A ranger automatically has Track as a bonus
feat. He need not select it.</p>
    <p>This feat does not allow you to find or follow the tracks made
by a subject of a pass without trace spell.</p>
    <h5>TRAMPLE [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Ride 1 rank, Mounted
Combat.</p>
    <p><b>Benefit</b>: When you attempt to overrun an opponent while
mounted, your target may not choose to avoid you. Your mount may make
one hoof attack against any target you knock down, gaining the standard
+4 bonus on attack rolls against prone targets.</p>
    <p><b>Special</b>: A fighter may select Trample as one of his
fighter bonus feats.</p>
    <h5>TWO-WEAPON DEFENSE [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 15, Two-Weapon
Fighting.</p>
    <p><b>Benefit</b>: When wielding a double weapon or two weapons
(not including natural weapons or unarmed strikes), you gain a +1
shield bonus to your AC.</p>
    <p>When you are fighting defensively or using the total defense
action, this shield bonus increases to +2.</p>
    <p><b>Special</b>: A fighter may select Two-Weapon Defense as one
of his fighter bonus feats.</p>
    <h5>TWO-WEAPON FIGHTING [GENERAL]</h5>
    <p className="initial">You can fight with a weapon in each hand. You
can make one extra attack each round with the second weapon.</p>
    <p><b>Prerequisite</b>: Dex 15.</p>
    <p><b>Benefit</b>: Your penalties on attack rolls for fighting
with two weapons are reduced. The penalty for your primary hand lessens
by 2 and the one for your off hand lessens by 6.</p>
    <p><b>Normal</b>: If you wield a second weapon in your off hand,
you can get one extra attack per round with that weapon. When fighting
in this way you suffer a &#8211;6 penalty with your regular attack or attacks
with your primary hand and a &#8211;10 penalty to the attack with your off
hand. If your off-hand weapon is light the penalties are reduced by 2
each. (An unarmed strike is always considered light.)</p>
    <p><b>Special</b>: A 2nd-level ranger who has chosen the
two-weapon combat style is treated as having Two-Weapon Fighting, even
if he does not have the prerequisite for it, but only when he is
wearing light or no armor.</p>
    <p>A fighter may select Two-Weapon Fighting as one of his fighter
bonus feats.</p>
    <h5>WEAPON FINESSE [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Base attack bonus +1.</p>
    <p><b>Benefit</b>: With a light weapon, rapier, whip, or spiked
chain made for a creature of your size category, you may use your
Dexterity modifier instead of your Strength modifier on attack rolls.
If you carry a shield, its armor check penalty applies to your attack
rolls.</p>
    <p><b>Special</b>: A fighter may select Weapon Finesse as one of
his fighter bonus feats.</p>
    <p>Natural weapons are always considered light weapons.</p>
    <h5>WEAPON FOCUS [GENERAL]</h5>
    <p className="initial">Choose one type of weapon. You can also choose
unarmed strike or grapple (or ray, if you are a spellcaster) as your
weapon for purposes of this feat.</p>
    <p><b>Prerequisites</b>: Proficiency with selected weapon, base
attack bonus +1.</p>
    <p><b>Benefit</b>: You gain a +1 bonus on all attack rolls you
make using the selected weapon.</p>
    <p><b>Special</b>: You can gain this feat multiple times. Its
effects do not stack. Each time you take the feat, it applies to a new
type of weapon.</p>
    <p>A fighter may select Weapon Focus as one of his fighter bonus
feats. He must have Weapon Focus with a weapon to gain the Weapon
Specialization feat for that weapon.</p>
    <h5>WEAPON SPECIALIZATION [GENERAL]</h5>
    <p className="initial">Choose one type of weapon for which you have
already selected the Weapon Focus feat. You can also choose unarmed
strike or grapple as your weapon for purposes of this feat. You deal
extra damage when using this weapon.</p>
    <p><b>Prerequisites</b>: Proficiency with selected weapon, Weapon
Focus with selected weapon, fighter level 4th.</p>
    <p><b>Benefit</b>: You gain a +2 bonus on all damage rolls you
make using the selected weapon.</p>
    <p><b>Special</b>: You can gain this feat multiple times. Its
effects do not stack. Each time you take the feat, it applies to a new
type of weapon.</p>
    <p>A fighter may select Weapon Specialization as one of his
fighter bonus feats.</p>
    <h5>WHIRLWIND ATTACK [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 13, Int 13, Combat
Expertise, Dodge, Mobility, Spring Attack, base attack bonus +4.</p>
    <p><b>Benefit</b>: When you use the full attack action, you can
give up your regular attacks and instead make one melee attack at your
full base attack bonus against each opponent within reach.</p>
    <p>When you use the Whirlwind Attack feat, you also forfeit any
bonus or extra attacks granted by other feats, spells, or abilities.</p>
    <p><b>Special</b>: A fighter may select Whirlwind Attack as one
of his fighter bonus feats.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      